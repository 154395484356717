.App {
  text-align: center;
  background-color: #f0f0f0;
  min-width: 100%;
}

.section {
  text-align: left;
  max-width: 1000px;
  margin: 4rem auto auto;
}

.center-vert-hor {
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
}
